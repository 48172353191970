import * as React from "react";
import { IconProps } from "../types";

export const SelfMotivatedIcon = ({ color = "#155B71", className }: IconProps) => (
  <svg
    className={className}
    width="49"
    height="56"
    viewBox="0 0 49 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.0019 27.9999L30.3819 18.6199C30.579 18.423 30.7354 18.1892 30.8421 17.9318C30.9488 17.6744 31.0037 17.3985 31.0037 17.1199C31.0037 16.8413 30.9488 16.5654 30.8421 16.308C30.7354 16.0506 30.579 15.8168 30.3819 15.6199V15.6199C30.185 15.4228 29.9511 15.2664 29.6938 15.1597C29.4364 15.053 29.1605 14.998 28.8819 14.998C28.6032 14.998 28.3274 15.053 28.07 15.1597C27.8126 15.2664 27.5788 15.4228 27.3819 15.6199L16.0019 26.9999L4.62186 15.6199C4.42496 15.4228 4.19113 15.2664 3.93375 15.1597C3.67636 15.053 3.40048 14.998 3.12186 14.998C2.84323 14.998 2.56735 15.053 2.30997 15.1597C2.05258 15.2664 1.81875 15.4228 1.62186 15.6199V15.6199C1.42472 15.8168 1.26833 16.0506 1.16162 16.308C1.05492 16.5654 1 16.8413 1 17.1199C1 17.3985 1.05492 17.6744 1.16162 17.9318C1.26833 18.1892 1.42472 18.423 1.62186 18.6199L11.0019 27.9999V54.9999H21.0019V27.9999Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M43.002 47V29" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M29.002 41V27"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="2 4 12 2 4 12"
    />
    <path
      d="M16.002 21C18.2111 21 20.002 19.2091 20.002 17C20.002 14.7909 18.2111 13 16.002 13C13.7928 13 12.002 14.7909 12.002 17C12.002 19.2091 13.7928 21 16.002 21Z"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path d="M16.002 40V55" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M37.0016 55V23H47.0016L28.0016 1L20.1016 10.14"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
