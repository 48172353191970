import * as React from "react";

export const DiamondImage = () => (
  <svg height="96" viewBox="0 0 78 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2706_297)">
      <path d="M0.177734 17.1477L39.0024 64L26.0001 17.1477H0.177734Z" fill="#ABC8E2" />
      <path d="M51.9997 17.1477L39.002 64L77.8221 17.1477H51.9997Z" fill="#375D81" />
      <path d="M13.002 0L25.9997 17.1477L39.002 0H13.002Z" fill="#ABC8E2" />
      <path d="M26 17.1477L39.0023 64L52 17.1477H26Z" fill="#C4D7ED" />
      <path d="M51.9997 17.1477L65.002 0H39.002L51.9997 17.1477Z" fill="#ABC8E2" />
      <path d="M65.0023 0L52 17.1477H78L65.0023 0Z" fill="#183152" />
      <path d="M13.0023 0L0 17.1477H26L13.0023 0Z" fill="#C4D7ED" />
      <path d="M39.0023 0L26 17.1477H52L39.0023 0Z" fill="#375D81" />
    </g>
    <defs>
      <clipPath id="clip0_2706_297">
        <rect width="78" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
