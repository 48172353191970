import React from "react";
import { useTranslations } from "../translations";
import { CheckListIcon } from "../icons/CheckListIcon";
import { DDPlusLogo } from "../icons/DDPlusLogo";
import { FreeTrialButton } from "./FreeTrialButton";

export const FeaturesBlock = () => {
  const tt = useTranslations();

  return (
    <div className="bg-white max-w-screen-xl mx-auto px-6 py-16 rounded-2xl shadow-md">
      <div className="flex justify-center mb-5">
        <DDPlusLogo />
      </div>
      <h2 className="display-xxxs sm:display-xs text-center m-0 mb-6 md:mb-8">
        {tt.featuresTitle.split("\n").map((value, i) => (
          <div key={i}>
            {value}
            <br />
          </div>
        ))}
      </h2>
      <p className="text-dark-green text-center body-m md:body-xl mt-0 md:mt-0">{tt.featuresSubTitle}</p>
      <div className="max-w-3xl mx-auto">
        <ul className="p-0 grid md:grid-cols-2 gap-x-10 gap-y-2 list-none body-m md:body-xl">
          <li className="flex items-center">
            <CheckListIcon /> <span className="ml-3">{tt.featureOneText}</span>
          </li>
          <li className="flex items-center">
            <CheckListIcon /> <span className="ml-3">{tt.featureTwoText}</span>
          </li>
          <li className="flex items-center">
            <CheckListIcon /> <span className="ml-3">{tt.featureThreeText}</span>
          </li>
          <li className="flex items-center">
            <CheckListIcon /> <span className="ml-3">{tt.featureFourText}</span>
          </li>
          <li className="flex items-center">
            <CheckListIcon /> <span className="ml-3">{tt.featureFiveText}</span>
          </li>
          <li className="flex items-center">
            <CheckListIcon /> <span className="ml-3">{tt.featureSixText}</span>
          </li>
          <li className="flex items-center">
            <CheckListIcon /> <span className="ml-3">{tt.featureSevenText}</span>
          </li>
          <li className="flex items-center">
            <CheckListIcon /> <span className="ml-3">{tt.featureEightText}</span>
          </li>
        </ul>
      </div>

      <div className="flex justify-center mt-10">
        <FreeTrialButton buttonText={tt.tryItFreeText} defaultStyle={true} />
      </div>
    </div>
  );
};
