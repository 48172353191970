import * as React from "react";
import { IconProps } from "../types";

export const TylbIcon = ({ color = "#155B71", className }: IconProps) => (
  <svg
    className={className}
    width="56"
    height="52"
    viewBox="0 0 56 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2594_1202)">
      <path d="M41 51V39H23V51" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M25 39V36.87C24.9995 36.284 24.8702 35.7054 24.6214 35.1749C24.3726 34.6444 24.0102 34.175 23.56 33.8L20.44 31.2C19.9898 30.825 19.6274 30.3556 19.3786 29.8251C19.1298 29.2946 19.0005 28.716 19 28.13V25H21V17.19C20.9947 16.7763 21.071 16.3657 21.2244 15.9815C21.3778 15.5973 21.6055 15.2471 21.8943 14.9508C22.1831 14.6546 22.5274 14.4181 22.9076 14.255C23.2877 14.0919 23.6963 14.0052 24.11 14C24.4489 14.3057 24.8559 14.5262 25.2972 14.6431C25.7384 14.76 26.2012 14.7701 26.6471 14.6723C27.0929 14.5745 27.5091 14.3718 27.8609 14.0809C28.2127 13.7901 28.4901 13.4195 28.67 13C29.2404 13.0024 29.7983 13.1673 30.2783 13.4755C30.7583 13.7837 31.1404 14.2223 31.38 14.74C31.9206 14.268 32.6124 14.0055 33.33 14C34.052 13.9915 34.7529 14.2436 35.304 14.7102C35.855 15.1768 36.2193 15.8265 36.33 16.54C36.6502 16.3138 37.0125 16.1538 37.3955 16.0697C37.7785 15.9855 38.1744 15.9788 38.56 16.05C39.2699 16.1928 39.9072 16.5804 40.3604 17.1452C40.8137 17.71 41.0542 18.416 41.04 19.14V29.79C41.0408 30.5799 40.8076 31.3524 40.37 32.01L39.37 33.5C39.1494 33.8282 39.0311 34.2145 39.03 34.61V39"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47 29V25H41V21H55V25V29H47Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M51 29V26" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M35.5 8.14977L37.55 2.50977"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.21 11.4501L45.07 6.8501"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.4502 16.5L50.6502 13.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9302 6.8501L16.7902 11.4501"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4502 2.50977L22.5002 8.14977"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M29 1V7" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7 25C8.10457 25 9 24.1046 9 23C9 21.8954 8.10457 21 7 21C5.89543 21 5 21.8954 5 23C5 24.1046 5.89543 25 7 25Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0001 21H16.7401C16.2533 19.1147 15.0957 17.4716 13.4841 16.3788C11.8725 15.286 9.91774 14.8184 7.98611 15.0638C6.05449 15.3091 4.27866 16.2506 2.99149 17.7116C1.70431 19.1726 0.994141 21.0529 0.994141 23C0.994141 24.9472 1.70431 26.8275 2.99149 28.2885C4.27866 29.7495 6.05449 30.6909 7.98611 30.9363C9.91774 31.1816 11.8725 30.7141 13.4841 29.6212C15.0957 28.5284 16.2533 26.8853 16.7401 25H21.0001V21Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M27 43V45" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_2594_1202">
        <rect width="56" height="52" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
