import * as React from "react";

export const DDPlusLogo = () => (
  <svg width="97" height="86" viewBox="0 0 97 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M70.5096 43.6699V59.0248H55.6433V69.6817H70.601V85.0366H81.1702V69.6817H96.0456V59.0248H81.0787V43.6699H70.5096Z"
      fill="#E4C668"
    />
    <path
      d="M42.5874 42.5731C42.5874 30.8193 33.0514 21.2865 21.2937 21.2865H0V63.8597H21.2937C33.0514 63.8597 42.5874 54.3269 42.5874 42.5731ZM10.6514 31.9252H21.2937C27.1726 31.9252 31.936 36.6871 31.936 42.564C31.936 48.4408 27.1726 53.2118 21.2937 53.2118H10.6514V31.9252Z"
      fill="#3C3C3C"
    />
    <path
      d="M21.2937 0H0V10.6478H21.2937C38.9303 10.6478 53.2297 24.9425 53.2297 42.5731C53.2297 60.2038 38.9303 74.4984 21.2937 74.4984H0V85.1371H21.2937C33.5269 85.1371 44.544 79.9731 52.3063 71.7199L52.48 56.0269H61.6869C63.0948 51.7952 63.872 47.271 63.872 42.564C63.8811 19.0565 44.8091 0 21.2937 0Z"
      fill="#3C3C3C"
    />
  </svg>
);
