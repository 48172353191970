import * as React from "react";
import { IconProps } from "../types";

export const PomIcon = ({ color = "#155B71", className }: IconProps) => (
  <svg
    className={className}
    width="62"
    height="70"
    viewBox="0 0 62 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2593_981)">
      <path
        d="M37.7686 46.2773L34.0658 47.7906"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.559 54.3912C21.4236 54.7541 22.397 54.7587 23.265 54.4039C24.133 54.0492 24.8245 53.3642 25.1874 52.4996C25.5504 51.635 25.5549 50.6616 25.2002 49.7937C24.8455 48.9257 24.1605 48.2341 23.2959 47.8712L21.9919 47.3238C21.5638 47.1441 21.1045 47.0505 20.6402 47.0483C20.1759 47.0461 19.7157 47.1354 19.2859 47.3111C18.8561 47.4867 18.4652 47.7453 18.1353 48.072C17.8055 48.3988 17.5432 48.7873 17.3635 49.2154C17.1838 49.6435 17.0902 50.1028 17.088 50.5671C17.0858 51.0314 17.175 51.4916 17.3507 51.9213C17.5263 52.3511 17.7849 52.7421 18.1117 53.072C18.4384 53.4018 18.8269 53.6641 19.255 53.8438L20.559 54.3912Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5327 46.9854C18.3973 47.3483 19.3706 47.3529 20.2386 46.9982C21.1066 46.6434 21.7981 45.9584 22.1611 45.0938C22.524 44.2292 22.5286 43.2559 22.1738 42.3879C21.8191 41.5199 21.1341 40.8284 20.2695 40.4654L18.9655 39.9181C18.5374 39.7384 18.0781 39.6447 17.6138 39.6425C17.1495 39.6404 16.6894 39.7296 16.2596 39.9053C15.8298 40.0809 15.4388 40.3395 15.1089 40.6663C14.7791 40.993 14.5168 41.3815 14.3371 41.8096C14.1574 42.2377 14.0638 42.697 14.0616 43.1613C14.0594 43.6256 14.1487 44.0858 14.3243 44.5156C14.5 44.9454 14.7586 45.3364 15.0853 45.6662C15.4121 45.9961 15.8006 46.2583 16.2287 46.438L17.5327 46.9854Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5063 39.5801C15.3709 39.943 16.3443 39.9476 17.2122 39.5929C18.0802 39.2382 18.7718 38.5532 19.1347 37.6886C19.4976 36.824 19.5022 35.8506 19.1475 34.9826C18.7927 34.1146 18.1077 33.4231 17.2431 33.0602L15.9391 32.5128C15.511 32.3331 15.0517 32.2395 14.5874 32.2373C14.1231 32.2351 13.663 32.3244 13.2332 32.5C12.8034 32.6757 12.4124 32.9342 12.0826 33.261C11.7527 33.5877 11.4905 33.9763 11.3108 34.4044C11.131 34.8325 11.0374 35.2918 11.0352 35.7561C11.033 36.2203 11.1223 36.6805 11.298 37.1103C11.4736 37.5401 11.7322 37.9311 12.0589 38.2609C12.3857 38.5908 12.7742 38.853 13.2023 39.0328L14.5063 39.5801Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1557 23.2562L9.54772 22.1615C9.11961 21.9818 8.6603 21.8881 8.19601 21.8859C7.73172 21.8837 7.27156 21.973 6.84177 22.1487C6.41199 22.3243 6.021 22.5829 5.69114 22.9096C5.36129 23.2364 5.09903 23.6249 4.91933 24.053C4.73962 24.4811 4.64599 24.9404 4.6438 25.4047C4.6416 25.869 4.73088 26.3292 4.90653 26.759C5.08218 27.1888 5.34076 27.5798 5.66751 27.9096C5.99426 28.2395 6.38278 28.5017 6.81088 28.6814L15.9388 32.513"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9391 32.5129L10.2645 18.6277C9.86312 17.6457 9.86832 16.5444 10.2789 15.5662C10.6895 14.5881 11.4719 13.8131 12.4539 13.4117L29.1161 6.60211C30.0982 6.20077 31.1994 6.20598 32.1776 6.61658C33.1558 7.02719 33.9308 7.80956 34.3321 8.79158L48.7079 43.9674C49.1093 44.9494 49.1041 46.0506 48.6935 47.0288C48.2828 48.007 47.5005 48.782 46.5185 49.1833L29.8562 55.9929C28.8742 56.3943 27.773 56.3891 26.7948 55.9785C25.8166 55.5678 25.0416 54.7855 24.6403 53.8035L24.5041 53.4702"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.559 54.3912C21.4236 54.7541 22.397 54.7587 23.265 54.4039C24.133 54.0492 24.8245 53.3642 25.1874 52.4996C25.5504 51.635 25.5549 50.6616 25.2002 49.7937C24.8455 48.9257 24.1605 48.2341 23.2959 47.8712L21.9919 47.3238C21.5638 47.1441 21.1045 47.0505 20.6402 47.0483C20.1759 47.0461 19.7157 47.1354 19.2859 47.3111C18.8561 47.4867 18.4652 47.7453 18.1353 48.072C17.8055 48.3988 17.5432 48.7873 17.3635 49.2154C17.1838 49.6435 17.0902 50.1028 17.088 50.5671C17.0858 51.0314 17.175 51.4916 17.3507 51.9213C17.5263 52.3511 17.7849 52.7421 18.1117 53.072C18.4384 53.4018 18.8269 53.6641 19.255 53.8438L20.559 54.3912Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.4927 30.3315L25.2359 34.1147"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.2365 22.757C28.9355 22.0205 28.3542 21.4337 27.6206 21.1258C26.887 20.8178 26.061 20.8139 25.3245 21.1149C24.588 21.4159 24.0012 21.9972 23.6933 22.7308C23.3853 23.4645 23.3814 24.2904 23.6824 25.0269"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.4801 24.0293C23.0284 23.8095 22.5288 23.7066 22.027 23.7301C21.5252 23.7535 21.0374 23.9027 20.6082 24.1637C20.179 24.4248 19.8223 24.7894 19.5707 25.2242C19.3191 25.659 19.1807 26.15 19.1682 26.6522C19.1557 27.1544 19.2695 27.6517 19.4992 28.0984C19.7289 28.5452 20.0671 28.9271 20.4827 29.2092C20.8984 29.4913 21.3782 29.6644 21.8782 29.7128C22.3782 29.7612 22.8824 29.6833 23.3444 29.4862L26.7492 37.8173L36.006 34.0342L32.6012 25.7031C33.0691 25.5201 33.4835 25.2227 33.8065 24.8379C34.1295 24.4532 34.3507 23.9935 34.4498 23.5011C34.5489 23.0086 34.5228 22.4991 34.3738 22.0194C34.2249 21.5396 33.9578 21.105 33.5972 20.7553C33.2365 20.4056 32.7939 20.1521 32.3098 20.0179C31.8257 19.8838 31.3156 19.8734 30.8265 19.9877C30.3373 20.1019 29.8846 20.3372 29.51 20.6718C29.1354 21.0065 28.8509 21.4299 28.6824 21.9032"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.3849 23.6025C39.7829 22.1295 39.7908 20.4777 40.4067 19.0104C41.0226 17.5431 42.1961 16.3806 43.6692 15.7786C44.1602 15.5779 44.7108 15.5805 45.1999 15.7858C45.689 15.9911 46.0765 16.3823 46.2771 16.8733L55.7349 40.0153C58.4512 46.6617 54.0251 54.7578 45.8421 58.1021C41.9828 59.7434 37.6696 59.9767 33.6558 58.7613"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2593_981">
        <rect
          width="44"
          height="56"
          fill="white"
          transform="matrix(-0.925678 0.378311 0.378311 0.925678 40.7715 0.758301)"
        />
      </clipPath>
    </defs>
  </svg>
);
