export const getContentFromURL = (referrerURL?: string) => {
  const defaultContent = "new-member";
  if (referrerURL) {
    const url = new URL(referrerURL);
    return (
      url.pathname
        .split("/")
        .filter((value) => value)
        .pop() || defaultContent
    );
  } else {
    return defaultContent;
  }
};
