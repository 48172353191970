import React, { useContext } from "react";
import { SignUpPage, SignUpStep } from "../../../pages";
import { Link as CustomLink, useNavigate } from "../../../components/Link/Link";
import { getContentFromURL } from "../utils";
import { LoginContext } from "../../../components/LoginProvider/LoginProvider";

export const FreeTrialButton = ({
  buttonText,
  defaultStyle,
}: {
  buttonText: string;
  defaultStyle?: boolean;
}) => {
  const { user, premium } = useContext(LoginContext);

  const buttonStyle = defaultStyle
    ? "bg-green text-white hover:bg-light-green"
    : "bg-white text-blue-500 border-2 border-blue-500 hover:opacity-80";

  const navigate = useNavigate();

  const className = `${buttonStyle} block text-center max-w-max rounded-full whitespace-nowrap px-8 py-3 text-base font-medium`;

  return (
    <>
      <CustomLink
        to={SignUpPage}
        params={{ content: getContentFromURL() }}
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          event.preventDefault();
          navigate({
            to: SignUpPage,
            params: {
              content:
                new URLSearchParams(window?.location.search).get("content") ||
                getContentFromURL(document.referrer) ||
                undefined,
              source: new URLSearchParams(window?.location.search).get("source") || undefined,
              step: (user && !premium && SignUpStep.PLAN) || SignUpStep.ACCOUNT,
            },
          });
        }}
        className={className}
      >
        {buttonText}
      </CustomLink>
    </>
  );
};
