import * as React from "react";
import { IconProps } from "../types";

export const PlusIcon = ({ className, color = "#00B67A", ...rest }: IconProps) => (
  <svg
    className={className}
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <line
      x1="1"
      y1="-1"
      x2="35"
      y2="-1"
      transform="matrix(-1 0 0 1 36 18)"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="1"
      y1="-1"
      x2="35"
      y2="-1"
      transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 17 36)"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
