import React from "react";
import { useTranslations } from "../translations";
import { Accordian } from "./Accordian";

export const FAQSection = () => {
  const tt = useTranslations();

  return (
    <>
      <h2 className="display-xxs sm:display-xs text-center m-0 mb-10 px-10">
        {tt.frequentlyAskedQuestionsTitle}
      </h2>
      <div>
        <Accordian
          question={tt.frequentlyAskedQuestionOneTitle}
          answer={tt.frequentlyAskedQuestionOneContent}
        />
        <Accordian
          question={tt.frequentlyAskedQuestionTwoTitle}
          answer={tt.frequentlyAskedQuestionTwoContent}
        />
        <Accordian
          question={tt.frequentlyAskedQuestionThreeTitle}
          answer={tt.frequentlyAskedQuestionThreeContent}
        />
      </div>
    </>
  );
};
