import * as React from "react";
import { IconProps } from "../types";

export const DisIcon = ({ color = "#155B71", className }: IconProps) => (
  <svg
    className={className}
    width="49"
    height="44"
    viewBox="0 0 49 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2593_994)">
      <path
        d="M45.5298 8V10C45.5298 10.2652 45.4245 10.5196 45.2369 10.7071C45.0494 10.8946 44.795 11 44.5298 11H36.5298C36.2646 11 36.0102 10.8946 35.8226 10.7071C35.6351 10.5196 35.5298 10.2652 35.5298 10V2C35.5298 1.73478 35.6351 1.48043 35.8226 1.29289C36.0102 1.10536 36.2646 1 36.5298 1H42.5298"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.5298 1L45.5298 3L41.5198 7L39.5298 5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.5298 24V26C45.5298 26.2652 45.4245 26.5196 45.2369 26.7071C45.0494 26.8946 44.795 27 44.5298 27H36.5298C36.2646 27 36.0102 26.8946 35.8226 26.7071C35.6351 26.5196 35.5298 26.2652 35.5298 26V18C35.5298 17.7348 35.6351 17.4804 35.8226 17.2929C36.0102 17.1054 36.2646 17 36.5298 17H42.5298"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.5298 17L45.5298 19L41.5198 23L39.5298 21"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.5298 40V42C45.5298 42.2652 45.4245 42.5196 45.2369 42.7071C45.0494 42.8946 44.795 43 44.5298 43H36.5298C36.2646 43 36.0102 42.8946 35.8226 42.7071C35.6351 42.5196 35.5298 42.2652 35.5298 42V34C35.5298 33.7348 35.6351 33.4804 35.8226 33.2929C36.0102 33.1054 36.2646 33 36.5298 33H42.5298"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.5298 33L45.5298 35L41.5198 39L39.5298 37"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.41992 12.05V1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.83984 1V12.05" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M1 1V14.26C1 15.4323 1.46577 16.5565 2.29468 17.3854C3.12359 18.2143 4.24779 18.68 5.42004 18.68V40.79C5.42004 41.0811 5.47755 41.3693 5.58923 41.6381C5.70092 41.9068 5.86452 42.1509 6.0708 42.3563C6.27708 42.5616 6.52197 42.7242 6.79126 42.8347C7.06054 42.9451 7.34895 43.0013 7.64001 43V43C8.22614 43 8.7883 42.7671 9.20276 42.3527C9.61721 41.9382 9.84998 41.3761 9.84998 40.79V18.68C11.0222 18.68 12.1466 18.2143 12.9755 17.3854C13.8044 16.5565 14.27 15.4323 14.27 14.26V1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6899 17.42C18.6899 21.98 20.4699 25.95 23.1099 28.08V40.79C23.1099 41.3761 23.3427 41.9382 23.7572 42.3527C24.1717 42.7671 24.7338 43 25.3199 43C25.9061 43 26.4681 42.7671 26.8826 42.3527C27.297 41.9382 27.5299 41.3761 27.5299 40.79V1C22.6499 1 18.6899 8.35001 18.6899 17.42Z"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2593_994">
        <rect width="48.53" height="44" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
