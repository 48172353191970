import React, { useState } from "react";
import { PlusIcon } from "../icons/PlusIcon";

interface AccordianProps {
  question: string;
  answer: string;
}

export const Accordian = ({ question, answer }: AccordianProps) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="w-full bg-white p-5 md:px-10 md:py-6 mb-5 rounded-xl shadow-sm">
      <div className="flex flex-row cursor-pointer" onClick={() => setIsActive(!isActive)}>
        <div className="w-10/12 md:m-0 text-base md:text-2xl m-0 leading-normal md:leading-normal">
          {question}
        </div>
        <div className="w-2/12 flex items-center justify-center">
          <PlusIcon
            className={`scale-75 md:scale-100 transition-all duration-200 ml-auto ${
              isActive ? "rotate-45" : ""
            }`}
          />
        </div>
      </div>
      <div
        className={`md:w-11/12 transition transition-maxHeight duration-200 ease-in-out overflow-hidden ${
          isActive ? "max-h-96" : "max-h-0"
        }`}
      >
        <p className="md:body-l body-s m-0 md:m-0 pt-2">{answer}</p>
      </div>
    </div>
  );
};
