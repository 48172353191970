import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { useTranslations } from "../translations";
import { DiamondImage } from "../icons/DimondImage";
import { TPStarImage } from "../icons/TPStarImage";

export const Highlights = () => {
  const imageData = useStaticQuery(graphql`
    query {
      bannerImage: file(relativePath: { eq: "new-member/most-visited-image.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 120, height: 109)
        }
      }
    }
  `);

  const tt = useTranslations();

  return (
    <div className="flex flex-col md:flex-row items-center space-y-12 md:space-y-0 md:items-start justify-center mx-auto">
      <div className="flex flex-col items-center justify-center px-2 lg:-mx-8 text-center md:w-1/3">
        <TPStarImage />
        <div className="m-0 mt-3 md:mb-0 md:mt-5 body-l md:body-xl">
          <span className="block font-medium">{tt.highlightOneTitle}</span>
          {tt.highlightOneContent.split("\n").map((value, i) => (
            <div key={i}>
              {value}
              <br className="hidden md:block" />
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col items-center justify-center px-2 lg:-mx-8 text-center md:w-1/3">
        <GatsbyImage
          image={imageData.bannerImage?.childImageSharp?.gatsbyImageData}
          alt={tt.highlightTwoTitle}
          style={{ height: "96px" }}
          imgStyle={{ objectFit: "contain" }}
        />
        <div className="m-0 mt-3 md:mb-0 md:mt-5 body-l md:body-xl">
          <span className="block font-medium">{tt.highlightTwoTitle}</span>
          {tt.highlightTwoContent.split("\n").map((value, i) => (
            <div key={i}>
              {value}
              <br className="hidden md:block" />
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col items-center justify-center px-2 lg:-mx-8 text-center md:w-1/3">
        <DiamondImage />
        <div className="m-0 mt-3 md:mb-0 md:mt-5 body-l md:body-xl">
          <span className="block font-medium">{tt.highlightThreeTitle}</span>
          {tt.highlightThreeContent.split("\n").map((value, i) => (
            <div key={i}>
              {value}
              <br className="hidden md:block" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
