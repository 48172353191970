import * as React from "react";

function TrustpilotFiveStars(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 139 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M113.636 0h25v25h-25V0zM85.227 0h25v25h-25V0zM56.818 0h25v25h-25V0zM28.41 0h25v25h-25V0zM0 0h25v25H0V0z"
        fill="#00B67A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.727 10.09h-7.811l-2.413-7.817-2.42 7.818-7.81-.01 6.326 4.836-2.42 7.81 6.324-4.828 6.319 4.828-2.41-7.799-.003-.011 6.318-4.826zm-6.318 4.828l-3.906 2.98 4.45-1.212-.54-1.758-.004-.011v.001zm34.727-4.828h-7.811l-2.413-7.817-2.42 7.818-7.81-.01 6.325 4.836-2.42 7.81 6.325-4.828 6.319 4.828-2.41-7.8.54 1.76-4.448 1.212 3.905-2.98 6.318-4.83zm28.41 0h-7.812l-2.412-7.817-2.42 7.818-7.811-.01 6.325 4.836-2.42 7.81 6.326-4.828 6.318 4.828-2.41-7.8.54 1.76-4.448 1.212 3.905-2.98 6.319-4.83zm28.409 0h-7.812l-2.412-7.817-2.42 7.818-7.811-.01 6.326 4.836-2.42 7.81L97.73 17.9l6.318 4.828-2.411-7.804.542 1.763-4.45 1.213 3.906-2.98 6.319-4.83zm20.597 0h7.812l-6.319 4.828-3.905 2.98 4.449-1.212-.542-1.763 2.411 7.804-6.318-4.828-6.325 4.828 2.42-7.809-6.326-4.836 7.811.008 2.42-7.817 2.412 7.818v-.001z"
        fill="#fff"
      />
    </svg>
  );
}

export default TrustpilotFiveStars;
