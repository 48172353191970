import React from "react";
import { useTranslations } from "../translations";
import { IconCircle } from "../icons/IconCircle";
import { DisIcon } from "../icons/DisIcon";
import { PomIcon } from "../icons/PomIcon";
import { TylbIcon } from "../icons/TylbIcon";
import { SelfMotivatedIcon } from "../icons/SelfMotivatedIcon";
import { FreeTrialButton } from "./FreeTrialButton";

export const MembershipBenefits = () => {
  const tt = useTranslations();

  return (
    <>
      <h2 className="display-xs sm:display-s text-black text-center m-0 mx-4 mb-8 md:mb-12">
        {tt.membershipBenefitsHeading}
      </h2>

      <div className="flex flex-col md:flex-row md:justify-center items-center gap-8">
        <div className="rounded-xl md:py-5">
          <div className="flex flex-row md:flex-col items-start md:items-center space-x-4 md:space-x-0">
            <div className="w-16 h-16">
              <IconCircle icon={<DisIcon />} />
            </div>
            <div className="flex flex-col">
              <h4 className="m-0 md:mt-2 md:mb-3 headline-m text-left md:text-center">
                {tt.motivatedUSPTitle}
              </h4>
              <p className="mt-1 md:m-0 md:mb-4 body-m md:body-l max-w-xs text-left md:text-center">
                {tt.motivatedUSPContent}
              </p>
            </div>
          </div>
        </div>

        <div className="rounded-xl md:py-5">
          <div className="flex flex-row md:flex-col items-start md:items-center space-x-4 md:space-x-0">
            <div className="w-16 h-16">
              <IconCircle icon={<PomIcon />} />
            </div>
            <div className="flex flex-col">
              <h4 className="m-0 md:mt-2 md:mb-3 headline-m text-left md:text-center">
                {tt.simplicityUSPTitle}
              </h4>
              <p className="mt-1 md:m-0 md:mb-4 body-m md:body-l max-w-xs text-left md:text-center">
                {tt.simplicityUSPContent}
              </p>
            </div>
          </div>
        </div>

        <div className="rounded-xl md:py-5">
          <div className="flex flex-row md:flex-col items-start md:items-center space-x-4 md:space-x-0">
            <div className="w-16 h-16">
              <IconCircle icon={<TylbIcon />} />
            </div>
            <div className="flex flex-col">
              <h4 className="m-0 md:mt-2 md:mb-3 headline-m text-left md:text-center">{tt.lifeUSPTitle}</h4>
              <p className="mt-1 md:m-0 md:mb-4 body-m md:body-l max-w-xs text-left md:text-center">
                {tt.lifeUSPContent}
              </p>
            </div>
          </div>
        </div>

        <div className="rounded-xl md:py-5">
          <div className="flex flex-row md:flex-col items-start md:items-center space-x-4 md:space-x-0">
            <div className="w-16 h-16">
              <IconCircle icon={<SelfMotivatedIcon />} />
            </div>
            <div className="flex flex-col">
              <h4 className="m-0 md:mt-2 md:mb-3 headline-m text-left md:text-center">{tt.peaceUSPTitle}</h4>
              <p className="mt-1 md:m-0 md:mb-4 body-m md:body-l max-w-xs text-left md:text-center">
                {tt.peaceUSPContent}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-4 md:mt-10">
        <FreeTrialButton buttonText={tt.freeTrialText} defaultStyle={true} />
      </div>
    </>
  );
};
