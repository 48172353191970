import React, { useContext, useEffect, useMemo } from "react";
import { SEOReactHelmet } from "../../components/SEO/SEOReactHelmet";
import { FAQSection } from "./components/FAQSection";
import { FeaturesBlock } from "./components/FeaturesBlock";
import { HeaderSection } from "./components/HeaderSection";
import { Highlights } from "./components/Highlights";
import { SuccessStoriesCarousel } from "./components/SuccessStoriesCarousel";
import { FeaturesCarousel } from "./components/FeaturesCarousel";
import { useTranslations } from "./translations";
import { FreeTrialButton } from "./components/FreeTrialButton";
import { MembershipBenefits } from "./components/MembershipBenefits";
import { isBrowser } from "../../utils/ssr";
import { LoginContext } from "../../components/LoginProvider/LoginProvider";
import { NavBar } from "./components/NavBar";
import TrustpilotFiveStars from "./components/TrustpilotFiveStars";
import TrustpilotLogo from "./components/TrustpilotLogo";
import { useNavigate } from "../../components/Link/Link";
import { handleRedirection } from "../../components/LoginPage/utils";

const NewMemberPage = () => {
  const tt = useTranslations();

  const { loading: authLoading, loggedIn, user, premium } = useContext(LoginContext);

  // Handle redirect_to
  const navigate = useNavigate();
  const locationSearch = useMemo(() => (isBrowser() && window?.location.search) || "", []);
  useEffect(() => {
    if (!authLoading && loggedIn && user && premium) {
      handleRedirection(navigate, locationSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, user]);

  return (
    <>
      <SEOReactHelmet title={tt.newMemberPage} hideAppStore={true} />

      <NavBar />

      <div className="md:body-l body-m md:mb-0 mb-0">
        <section className="w-full bg-green-200 py-10 lg:py-0">
          <div className="max-w-screen-xl mx-auto">
            <HeaderSection />
          </div>
        </section>

        <section
          className="py-7 md:py-8 flex flex-row items-center justify-center space-x-4"
          style={{ backgroundColor: "#D1DCD5", height: "28px" }}
        >
          <span className="hidden md:block text-lg">{tt.ourCustomersSayText}</span>
          <span className="hidden md:block mr-2 text-lg font-medium">{tt.whatOurCustomersSayText}</span>

          <TrustpilotFiveStars height="28" />
          <span className="text-lg font-medium block ">{tt.reviewsText}</span>
          <div className="hidden md:block">
            <TrustpilotLogo height="28" />
          </div>
        </section>

        <section className="bg-white py-14">
          <div className="max-w-screen-xl mx-auto">
            <h2 className="display-xxs md:display-s text-center m-0 mb-6 md:mb-8 px-6 sm:px-10 whitespace-nowrap">
              {tt.getAccessText}
            </h2>

            <div>
              <FeaturesCarousel />
            </div>
            <div className="mt-10 md:flex justify-center hidden">
              <FreeTrialButton buttonText={tt.freeTrialText} defaultStyle={true} />
            </div>
          </div>
        </section>

        <section className="py-10 md:pt-20 md:pb-10 bg-light-beige content-auto">
          <div className="mx-auto xl:px-12 px-4">
            <MembershipBenefits />
          </div>
        </section>

        <section className="bg-white py-10 md:py-20 content-auto">
          <h2 className="display-xxs md:display-s text-center m-0 mb-6 md:mb-8 px-10">{tt.testimonialTitle}</h2>
          <div className="mx-auto max-w-2xl">
            <SuccessStoriesCarousel />
          </div>

          <div className="max-w-screen-xl mx-auto mt-16 xl:px-0 px-4 content-auto">
            <Highlights />
          </div>
        </section>

        <section className="py-10 md:py-20 md:bg-green-200 bg-light-beige px-4 md:px-20 content-auto">
          <FeaturesBlock />
        </section>

        <section className="bg-light-beige py-10 md:py-20 content-auto">
          <div className="max-w-screen-xl mx-auto xl:px-0 px-4">
            <FAQSection />
          </div>
        </section>
      </div>
    </>
  );
};

// ts-prune-ignore-next
export default NewMemberPage;
