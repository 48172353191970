import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { Locale } from "../../../types/Locale";
import { useTranslations } from "../translations";
import { FreeTrialButton } from "./FreeTrialButton";

export const HeaderSection = () => {
  const tt = useTranslations();

  const { locale } = useContext(pageContext);

  const imageData = useStaticQuery(graphql`
    query {
      desktopEN: file(relativePath: { eq: "new-member/hero-desktop-en.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 540)
        }
      }

      desktopSV: file(relativePath: { eq: "new-member/hero-desktop-sv.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 540)
        }
      }

      desktopES: file(relativePath: { eq: "new-member/hero-desktop-es.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 540)
        }
      }
    }
  `);

  return (
    <div className="flex lg:flex-row flex-col mx-auto mt-10">
      <div className="lg:w-6/12 xl:px-4 lg:min-h-[496px] px-6 flex items-center justify-center">
        <div className="flex flex-col items-center lg:items-start text-center lg:text-left">
          <h1 className="mt-4 lg:mt-0 px-7 lg:px-0">{tt.bannerHeading}</h1>
          <p className="text-lg md:text-xl my-4 max-w-xl">{tt.bannerContent}</p>

          <FreeTrialButton buttonText={tt.freeTrialText} defaultStyle={true} />
        </div>
      </div>

      <div className="lg:w-7/12 hidden lg:flex justify-end">
        <GatsbyImage
          image={
            locale === Locale.SV
              ? imageData.desktopSV?.childImageSharp?.gatsbyImageData
              : locale === Locale.ES
              ? imageData.desktopES?.childImageSharp?.gatsbyImageData
              : imageData.desktopEN?.childImageSharp?.gatsbyImageData
          }
          className="align-bottom"
          objectFit="contain"
          loading="eager"
          alt={tt.bannerHeading}
        />
      </div>
    </div>
  );
};
